import React, {useEffect, useRef, useState} from 'react';

import {Alert, Button, Typography} from 'spenda-ui-react';

import {FetchAdaptorDataStatus} from '../../../model/constants/Constants';
import useWorkflowAPI from '../../../services/useWorkflowAPI';
import {IQueueWorkFlowPayload, IWorkflowStatusResponse} from '../../../model/workflow/WorkflowResponse';
import ProgressBar from '../../ProgressBar';
import {ARDialogTemplate} from '../ARDialogTemplate';

interface IImportWorkflowsModalProps {
  open: boolean;
  onSuccess: () => void;
  message?: string;
  isFooter?: boolean;
  isLoading?: boolean;
  workflowId?: number;
  onPrimaryClick?: (importWorkflowId?: number) => void;
  onSecondaryClick?: () => void;
  queuePayload?: Partial<IQueueWorkFlowPayload>;
  isShowAlertStrip?: boolean;
}
export const ImportWorkflowsModal = (props: IImportWorkflowsModalProps) => {
  // Props
  const {
    open,
    isLoading,
    onSuccess,
    isFooter,
    message,
    queuePayload,
    isShowAlertStrip,
    onPrimaryClick,
    onSecondaryClick,
    workflowId,
  } = props;

  const progressTimer = useRef<NodeJS.Timeout | null>(null);
  const workflowStatusTimer = useRef<NodeJS.Timeout | null>(null);

  // State
  const [importPercentage, setImportPercentage] = useState<any>(0);
  const [importStatus, setImportStatus] = useState<string>(FetchAdaptorDataStatus.STARTED);
  const [importWorkflowId, setImportWorkflowId] = useState<number | undefined>(workflowId);

  // APIs
  const {checkWorkflowStatus, queueWorkflow} = useWorkflowAPI();

  // Constants
  let isMounted = true;

  useEffect(() => {
    if (importWorkflowId) {
      clearIntervals();
      loadingPercentageCalculator();
      checkImportStatus(importWorkflowId);
      startCheckImportStatusInterval(importWorkflowId);
    }
  }, [importWorkflowId]);

  useEffect(() => {
    queuePayload && startWorkFlow(queuePayload);
  }, [queuePayload]);

  useEffect(() => {
    return () => {
      isMounted = false;
      clearIntervals();
    };
  }, []);

  // Functions
  const startCheckImportStatusInterval = (worflowId: number) => {
    workflowStatusTimer.current = setInterval(() => {
      checkImportStatus(worflowId);
    }, 5000);
  };

  const clearIntervals = () => {
    workflowStatusTimer.current && clearInterval(workflowStatusTimer.current);
    progressTimer.current && clearInterval(progressTimer.current);
  };

  const checkImportStatus = (WorkflowId: number) => {
    checkWorkflowStatus(WorkflowId).then((data: IWorkflowStatusResponse) => {
      if (data.Status === 'Complete') {
        clearIntervals();
        setImportStatus(FetchAdaptorDataStatus.SUCCESSFUL);
        setImportPercentage(100);
        onSuccess();
      }
    });
  };

  const startWorkFlow = async (queuePayload: Partial<IQueueWorkFlowPayload>) => {
    setImportStatus(FetchAdaptorDataStatus.STARTED);
    setImportPercentage(0);
    loadingPercentageCalculator();
    const res = await queueWorkflow(queuePayload);
    if (isMounted) {
      setImportWorkflowId(res?.WorkflowID);
    }
  };

  const updateProgress = (value: number) => {
    setImportPercentage((state: number) => {
      if (value < 99) {
        return value;
      }
      return state;
    });
  };

  let loadingPercentageCalculator = async () => {
    if (progressTimer.current) {
      clearInterval(progressTimer.current);
    }
    let percentage = importPercentage;
    progressTimer.current = setInterval(() => {
      percentage = percentage + 1;
      updateProgress(percentage);
    }, 500);
  };

  const onClickContinueOnboarding = () => {
    clearIntervals();
    onPrimaryClick && onPrimaryClick(importWorkflowId);
  };

  if (isShowAlertStrip) {
    return (
      <Alert
        open
        className="absolute top-0 h-[58px] max-w-[650px] items-center self-center bg-primary text-sm font-semibold"
      >
        We’re currently importing your transactions from Capricorn, which takes about five minutes.
      </Alert>
    );
  }

  return (
    <ARDialogTemplate
      isFullScreen
      dialogContentClass="!h-full"
      bodyClass="!h-full !p-0"
      dialogProps={{
        size: 'xs',
        open: open,
        className: 'sm:!min-w-[565px] sm:!max-w-[565px] sm:!h-[412px]',
      }}
      body={
        <div className="relative !mt-0 h-full pb-16 sm:mt-24">
          <Typography
            variant="small"
            className="mb-12 px-6 pt-12 text-center text-base font-normal text-black-800 sm:mb-20 sm:pt-24 sm:text-lg"
          >
            {message}
          </Typography>
          <div className="mb-6 flex items-center justify-center px-6 sm:mb-12 sm:px-12">
            {importStatus === FetchAdaptorDataStatus.SUCCESSFUL && (
              <p id="simple-modal-description" className={`p-7 text-center text-base`}>
                Imported successfully!
              </p>
            )}
            {importStatus === FetchAdaptorDataStatus.STARTED && (
              <ProgressBar isWithoutText bgcolor={'hsl(var(--primary))'} completed={importPercentage} />
            )}
            {importStatus === FetchAdaptorDataStatus.FAILED && (
              <p id="simple-modal-description" className={`p-7 text-center text-base`}>
                Import Failed!
              </p>
            )}
          </div>
          {isFooter && (
            <div className="absolute bottom-0 flex h-[52px] w-full flex-row items-center justify-between gap-2.5 rounded-[6px] bg-[#EFEFEF] px-2.5">
              <Button
                data-autoid={`btnStayOnThisPage`}
                className="bg-white"
                onClick={onSecondaryClick}
                variant="outlined"
                color="primary"
              >
                Stay on this page
              </Button>
              <Button
                disabled={!importWorkflowId}
                loading={isLoading}
                variant="filled"
                color="primary"
                data-autoid={`btnContinueOnboarding`}
                onClick={onClickContinueOnboarding}
              >
                Continue Onboarding
              </Button>
            </div>
          )}
        </div>
      }
    />
  );
};
